import React from "react";
import { useDispatch } from 'react-redux';
import { toggleTermsModal } from "../actions/distribution";

interface Props {
  show: boolean
}

export default function(props: Props) {
  const dispatch = useDispatch();

  const toggleTermsModalHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    dispatch(toggleTermsModal(false));
  }

  return <div style={{ display: props.show ? "block" : "none"}} className="modal" id="TermsModal" tabIndex={-1} role="dialog" aria-labelledby="TermsModal">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">ENS Redistribution</h5>
          <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={toggleTermsModalHandler}>Close</button>
        </div>
        <div className="modal-body">
          <p>If you registered a stateofus.eth name in the Status app before October 31, 2021, then you can collect $ENS as part of this   voluntary redistribution. Check if you’re eligible and collect $ENS tokens at https://ens-collect.status.im. Subject to Terms and Conditions below, you can collect your tokens over a period of 3 months, between Tuesday July 5th 15:00 UTC and Oct 5th 15:00 UTC.</p>
          <p>On Nov. 8th, 2021, Status was allocated 46,296.3 ENS tokens as a thank you for the early integration of the Ethereum Name Service (ENS) into the Status ecosystem. In Status App you can:</p>
          <ul>
            <li>Register a stateofus.eth subdomain name</li>
            <li>Connect an existing ENS or stateofus.eth subdomain name to your chat</li>
            <li>Allow others to send messages to your ENS name</li>
            <li>Allow others to send tokens to your ENS name</li>
          </ul>
          <p>The $ENS allocation was part of an airdrop <a href="https://ens.mirror.xyz/cfvfKRpQSPtZJjPQOprWqEeqv2rytE7tQkxDg6ht7Oo" target="_blank">announced</a> by the ENS team on November 1st, 2021 as part of its transition into a DAO to govern the ENS protocol.</p>
          <p>The airdrop distributed tokens to users, i.e. addresses that had registered a .eth name, and contributors, i.e. individuals and organizations that contributed in significant ways to ENS over the prior four years; No tokens were distributed to users that had registered a subdomain, such as a stateofus.eth name (as the main purpose was to govern .eth).</p>
          <p><a href="https://discuss.status.im/t/governance-discussion-re-distribution-of-ens-token-allocation/2344" target="_blank">In this discuss post</a>, @petty laid out reasons for $ENS allocated to Status, to be redistributed to those who registered a stateofus.eth name:</p>
          <ul>
            <li>The developers of features that incentivize the use of SNT should be rewarded commensurate to the growth of the feature. This is a fundamental idea behind development and reward in Web3.</li>
            <li>The users of an incentivized feature (particularly early and earnest ones) should be rewarded for their contribution and engagement.</li>
            <li>As ENS is primarily a public good, the distribution of its governance token should be maximally distributed across its users, thus our oversized allocation as an organization should not be wielded by only a few individuals.</li>
            <li>ENS usernames in Status App are fully owned by their registrants while their SNT is locked up in the smart contract. It can be argued that the allocation does not fully belong to the Status organization, but also to those that have used the username feature, as the airdrop did not include subdomain accounts. That argument is hinged upon whether or not the ENS team feels it is the responsibility of the feature implementer to equally handle that distribution, as all subdomain use across ENS is not equal and outside the scope of the ENS team to decide.</li>
          </ul>
          <p><a href="https://snapshot.org/#/status/proposal/0xffc40f92cfde912fc7e0a9f9875db93ff12b04dc2e920e875e4b9e92cf22180d" target="_blank">A proposal on how to manage redistribution</a> was made and accepted with 99.99% of voting power. As a result of this proposal and efforts by @petty and @gravityblast, you can now collect $ENS tokens, subject to Terms and Conditions below.</p>
          <p>What’s the benefit of receiving $ENS tokens?</p>
          <p>$ENS is a governance token. While it holds value and can be traded, its purpose is to govern the ENS protocol in line with the ENS DAO’s constitution. After receiving your $ENS tokens, don’t forget to delegate! We of course recommend an ENS steward that is one of our very own, Simona Pop!</p>
          <p>Note that if you registered a .eth before October 31st, 2021 you were eligible to claim $ENS tokens through https://claim.ens.domains/. The airdrop finished on May 4th, 2022 and $ENS can no longer be claimed for .eth names. This was mentioned in the original discuss post.</p>
          <p>Have questions or a problem?</p>
          <p>First, you should make sure you’ve read and accepted Terms and Conditions and followed all the appropriate steps to collectyour $ENS tokens. If something is still wrong, you should check the FAQ category on our Discuss forum that will continuously be updated with common problems that we may not have foreseen.</p>
          <p>If that doesn’t solve your issue, please ask a question either in the #support channel on the Status Community Discord or the #ens-redistribution channel within the Status application.</p>
          <p>That’s it.</p>
          <p>Carefully read Terms and Conditions before participating in this redistribution. Don’t forget to delegate! </p>

          <h3>Terms and Conditions</h3>
          <p>1. This redistribution of $ENS tokens is made by Status to eligible Status App users only, subject to these Terms and Conditions and on a voluntary basis and does not create any legal entitlement or claim for any user to receive the $ENS tokens nor any legal entitlement or claim whatsoever against Status.</p>
          <p>2. Who is eligible to take part in this redistribution of $ENS tokens?
If you registered at least one stateofus.eth name before October 31st, 2021, then you are eligible, subject to these Terms and Conditions.</p>
          <p>3. How much $ENS tokens can an eligible user collect?
The amount you receive is weighted by time. The longer you’ve held your stateofus.eth name, the more $ENS you’ll receive. If you’ve registered multiple names on a given address, the name that you’ve registered and held longest at any time up until Oct 31st counts. If you’ve registered names from different addresses, you can receive using each address. Having multiple names registered with a single address does not increase the amount.</p>
          <p>This logic follows the proposal on Snapshot which was accepted with 99.99% of voting power.</p>
          <p>4. How can an eligible user collect $ENS tokens?</p>
          <ul>
            <li>Open the Status App</li>
            <li>Make sure you have a bit of ETH to cover gas (~$8 at time of writing)</li>
            <li>Open https://ens-collect.status.im in Status App browser (or any other Web3-enabled browser)</li>
            <li>Connect your wallet</li>
            <li>Select ‘Collect’</li>
            <li>Sign transaction (check contract address: 0xBB85398092B83A016935a17Fc857507b7851a071)</li>
            <li>Add the $ENS token manually (optional). It might take some time for $ENS tokens to show in your wallet. This is normal. To see the $ENS tokens appear faster add it manually (see FAQ)</li>
          </ul>
          <p>You can check eligibility and collect $ENS tokens under this redistribution in any other Web3 enabled browsers, or—for example—MetaMask or Frame. Just make sure to connect the account you used to register a stateofus.eth name. For this reason, we suggest using the Status App as chances are higher that you used the Status App account to register a stateofus.eth name.</p>
          <p>5. By accepting $ENS in this redistribution, you represent and confirm the following:</p>
          <ul>
            <li>you are (i) legally permitted to receive and hold and make use of the $ENS tokens in your jurisdiction and (ii) that no legal restrictions exist in your country of residence or domicile for entering into these Terms and Conditions or receiving $ENS tokens;</li>
            <li>you confirm that you are not and have not been involved in any type of activity associated with money laundering, terror financing, or any other applicable anti-corruption or anti bribery legislation, nor were ever subject, to any investigation by, or have received a request for information from any governmental body relating to corruption or bribery.</li>
            <li>you confirm not being listed or being associated with any person or entity being listed on the Swiss SECO’s Overall List of Sanctioned Individuals, Entities and Organizations, or any of the US Department of Commerce’s Denied Persons or Entity List, the US Department of Treasury’s Specially Designated Nationals or Blocked Persons Lists, the US Department of State’s Debarred Parties List or the EU Consolidated List of Persons, Groups and Entities Subject to EU Financial Sanctions.</li>
            <li>you hereby represent that neither you nor any person or entity directly or indirectly controlling, controlled by or under common control with you is a person identified as a terrorist or terrorist organization on any relevant lists maintained by governmental authorities.</li>
          </ul>
          <p>6. You expressly acknowledge and take full responsibility for the reception of the $ENS tokens to your address are fully aware and informed about the risks associated with the ownership and possession of crypto-tokens, including the risk of losing access to crypto-tokens due to loss of private key(s), custodial error or your error, risks associated with the used protocols, risk of mining attacks, risk of hacking and security weaknesses, risks associated with markets for crypto-tokens, risk of uninsured losses, risks associated with uncertain regulations and enforcement actions, risks arising from taxation, risk of an unfavourable fluctuation of the crypto-tokens and other unanticipated risks. Status is not responsible for any claims with respect to the receipt by you of the $ENS tokens.</p>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={toggleTermsModalHandler}>Close</button>
        </div>
      </div>
    </div>
  </div>;
}
