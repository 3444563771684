import claimData from "../data.json";
import { global } from '../global';
import { Claim, ClaimMap} from "../types";

export const DISTRIBUTION_INITIALIZING = "DISTRIBUTION_INITIALIZING";
export interface DistributionInitializingAction {
  type: typeof DISTRIBUTION_INITIALIZING
}

export const DISTRIBUTION_INITIALIZED = "DISTRIBUTION_INITIALIZED";
export interface DistributionInitializedAction {
  type: typeof DISTRIBUTION_INITIALIZED
  claim: Claim | undefined
}

export const DISTRIBUTION_TERMS_TOGGLED = "DISTRIBUTION_TERMS_TOGGLED";
export interface DistributionTermsToggledAction {
  type: typeof DISTRIBUTION_TERMS_TOGGLED
  accepted: boolean
}

export const DISTRIBUTION_TERMS_MODAL_TOGGLED = "DISTRIBUTION_TERMS_MODAL_TOGGLED";
export interface DistributionTermsModalToggledAction {
  type: typeof DISTRIBUTION_TERMS_MODAL_TOGGLED
  show: boolean
}

export type DistributionActions =
  DistributionInitializingAction |
  DistributionTermsToggledAction |
  DistributionTermsModalToggledAction |
  DistributionInitializedAction;

const distributionInitialized = (claim: Claim | undefined): DistributionActions => ({
  type: DISTRIBUTION_INITIALIZED,
  claim,
})

const distributionTermsToggled = (accepted: boolean): DistributionActions => ({
  type: DISTRIBUTION_TERMS_TOGGLED,
  accepted,
})

const distributionTermsModalToggled = (show: boolean): DistributionActions => ({
  type: DISTRIBUTION_TERMS_MODAL_TOGGLED,
  show,
})

export const initializeDistribution = (account: string) => {
  const claims: ClaimMap = claimData.claims;
  const claim = claims[account.toLowerCase()];
  return distributionInitialized(claim);
}

export const toggleTerms = (accepted: boolean) => {
  return distributionTermsToggled(accepted);
}

export const toggleTermsModal = (show: boolean) => {
  return distributionTermsModalToggled(show);
}
