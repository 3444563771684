import {
  DISTRIBUTION_INITIALIZED,
  DISTRIBUTION_TERMS_TOGGLED,
  DISTRIBUTION_TERMS_MODAL_TOGGLED,
  DistributionActions
} from "../actions/distribution";
import { Claim } from "../types";

export interface DistributionState {
  initialized: boolean
  found: boolean | undefined
  error: string | undefined
  claim: Claim | undefined
  termsAccepted: boolean
  showTermsModal: boolean
}

const initialState: DistributionState = {
  initialized: false,
  found: undefined,
  error: undefined,
  claim: undefined,
  termsAccepted: false,
  showTermsModal: false,
}

export const distributionReducer = (state: DistributionState = initialState, action: DistributionActions): DistributionState => {
  switch (action.type) {
    case DISTRIBUTION_INITIALIZED: {
      return {
        ...state,
        initialized: true,
        found: action.claim !== undefined,
        claim: action.claim
      }
    }

    case DISTRIBUTION_TERMS_TOGGLED: {
      return {
        ...state,
        termsAccepted: action.accepted,
      }
    }

    case DISTRIBUTION_TERMS_MODAL_TOGGLED: {
      return {
        ...state,
        showTermsModal: action.show,
      }
    }
  }

  return state;
}
