import React, { useEffect } from 'react';
import {
  useDispatch,
  useSelector,
  shallowEqual,
} from 'react-redux';
import { RootState } from "../reducers";
import { initializeDistribution } from "../actions/distribution";
import Claim from "./Claim";

const shortAddress = (address: string | undefined) => {
  if (address === undefined) {
    return "";
  }

  return `${address.slice(0, 16)}...${address.slice(28)}`;
}

export default function() {
  const dispatch = useDispatch();
  const props = useSelector((state: RootState) => ({
    distributionInitialized: state.distribution.initialized,
    account: state.web3.account,
    claim: state.distribution.claim,
    contractAddress: state.contract.address,
    web3Initialized: state.web3.initialized,
    contractInitialized: state.contract.initialized,
  }), shallowEqual);

  useEffect(() => {
    if (props.account != undefined && !props.distributionInitialized) {
      dispatch(initializeDistribution(props.account!));
    }
  }, [props.distributionInitialized, props.account]);

  return <>
    <div>
      {props.contractInitialized && <>
        {props.distributionInitialized && props.claim === undefined && <div className="row">
          <div className="col my-4">
            <h5>😕 No tokens to collect</h5>
            <p>No stateofus.eth name found or the name was registered after October 31st, 2021.
            Try connecting another account.</p>
          </div>
        </div>}

        {props.distributionInitialized && props.claim !== undefined && <div className="row">
          <div className="col my-4">
            <h5>🎉 You've got tokens to collect</h5>
          </div>
        </div>}

        {props.web3Initialized && <div className="ro2">
          <div className="col">
            <div className="text-muted mb-2">Address</div>
            {shortAddress(props.account)}
          </div>
        </div>}
      </>}


      {props.distributionInitialized && <>
        <Claim claim={props.claim} />
      </>}
    </div>
  </>;
}
