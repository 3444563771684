import React, { useEffect } from 'react';
import {
  useDispatch,
  useSelector,
  shallowEqual,
} from 'react-redux';
import { RootState } from "../reducers";
import { initializeDistribution, toggleTerms, toggleTermsModal } from "../actions/distribution";
import { Claim } from "../types";
import { initializeContract, claim } from "../actions/contract";
import BN from "bignumber.js";

interface Props {
  claim: Claim | undefined
}

const formatAmount = (claim: Claim | undefined, decimals: string | undefined) => {
  if (claim === undefined || decimals === undefined) {
    return "0";
  }

  const a = new BN(claim.amount);
  const d = new BN(decimals);
  return a.div(new BN(10).pow(d)).toString();
}

export default function(ownProps: Props) {
  const dispatch = useDispatch();
  const props = useSelector((state: RootState) => ({
    initialized: state.contract.initialized,
    contractError: state.contract.error,
    claimed: state.contract.claimed,
    tokenSymbol: state.contract.tokenSymbol,
    tokenDecimals: state.contract.tokenDecimals,
    claiming: state.contract.claiming,
    claimedNow: state.contract.claimedNow,
    claimError: state.contract.claimError,
    termsAccepted: state.distribution.termsAccepted,
    showTermsModal: state.distribution.showTermsModal,
  }), shallowEqual);

  useEffect(() => {
    if (!props.initialized) {
      dispatch(initializeContract(ownProps.claim));
    }
  }, [ownProps.claim, props.initialized]);

  const toggleTermsHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(toggleTerms(event.currentTarget.checked));
  }

  const toggleTermsModalHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    dispatch(toggleTermsModal(!props.showTermsModal));
  }

  const claimHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    if (ownProps.claim !== undefined) {
      dispatch(claim(ownProps.claim));
    }
  }

  if (!props.initialized) {
    return <p>loading...</p>;
  }

  return <div className="row">
    <div className="col">
      <div>
        <div className="text-muted mt-4 mb-2">You can collect</div>
        {props.initialized ? formatAmount(ownProps.claim, props.tokenDecimals!) : "0"}
        &nbsp;
        {props.initialized ? `$${props.tokenSymbol}` : ""}
      </div>

      <div className="mb-2">
          <input type="checkbox" id="termsCheckbox" onChange={toggleTermsHandler} checked={props.termsAccepted} />&nbsp;
          <label htmlFor="termsCheckbox">
            <strong>I hereby acknoledge that I've read and accept the</strong>
          </label>&nbsp;
          <strong>
            <a href="#" onClick={toggleTermsModalHandler}>Terms and Conditions</a>
          </strong>
      </div>

      {props.initialized && props.claimed && <div>
        <span className="badge bg-danger">Already collected.</span>
      </div>}

      {props.initialized && props.contractError !== undefined && <div>
        <span className="badge bg-danger text-truncate">{props.contractError}</span>
      </div>}

      {props.claimError !== undefined && <div>
        <span className="badge bg-danger d-inline-block text-truncate" style={{ maxWidth: 400 }}>{props.claimError}</span>
      </div>}

      {props.claimedNow ? <>
        <span className="badge bg-success">Collected correctly.</span>
      </> : <>
        <div className="my-3">
          <button
            disabled={props.claimed || props.claiming || !props.initialized || props.contractError !== undefined || !props.termsAccepted}
            className="btn btn-primary"
            onClick={claimHandler}>Collect</button>
        </div>
      </>}
    </div>
  </div>;
}
