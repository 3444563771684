import React, { useEffect } from 'react';
import classNames from 'classnames';
import { RootState } from '../reducers';
import {
  shallowEqual,
  useSelector,
  useDispatch,
} from 'react-redux';
import { initializeWeb3 } from "../actions/web3";
import TermsModal from "./TermsModal";

import "../../node_modules/bootstrap/scss/bootstrap.scss";
import "../styles/main.scss";

interface Props {
  children: JSX.Element
}

export default function(ownProps: Props) {
  const dispatch = useDispatch();
  const props = useSelector((state: RootState) => ({
    web3Initialized: state.web3.initialized,
    web3Error: state.web3.error,
    chainID: state.web3.chainID,
    showTermsModal: state.distribution.showTermsModal,
    paused: state.contract.paused,
  }), shallowEqual);

  useEffect(() => {
    if (!props.web3Initialized) {
      dispatch(initializeWeb3());
    }
  }, [props.web3Initialized, props.web3Error]);

  const connectHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(initializeWeb3());
  }

  return <div>
    <nav className="navbar navbar-light bg-white py-4">
      <div className="container-fluid">
        <img src="logo.svg" alt="" height="48" className="d-inline-block align-text-top" />
      </div>
    </nav>

    <div className="container">
      {props.web3Error !== undefined && <div>
        <div className="alert alert-danger col-12 col-lg-8 offset-lg-2" role="alert">
          {props.web3Error}
        </div>
      </div>}

      <section className="row justify-content-lg-center">
        <div className="col-12 col-lg-8">
          <div className="mt-1">
            <div className="">
              <h3 className="mb-4">
                Collect your $ENS
              </h3>
              <div className="mb-2">
                <p>Status is redistributing to people who registered a stateofus.eth name before October 31st, 2021.
                Connect your account to check if you're eligible. Use the account you used to register
                your stateofus.eth name. You can find it in the app under
                &nbsp;<strong>Profile &gt; Your ENS Name</strong>.</p>
              </div>

              <div>
                {!props.paused && ownProps.children}
                {props.paused && <div>
                  <strong>ENS redistribution hasn't started, is paused or closed. Please check back later!</strong>
                </div>}
              </div>

              {!props.web3Initialized && <div className="my-2 text-end">
                <button className="btn btn-primary" onClick={connectHandler}>CONNECT</button>
              </div>}
            </div>
          </div>
        </div>
      </section>
      </div>
      <TermsModal show={props.showTermsModal} />
    </div>;
}
