import { Claim } from "./types";

interface Config {
  contract: string
  token: string
}

const config: Config = {
  contract: "0xbb85398092b83a016935a17fc857507b7851a071",
  token: "0xC18360217D8F7Ab5e7c516566761Ea12Ce7F9D72",
}

export default config;
